import React, { Component } from 'react';
import './Anchor.less';

export default class Anchor extends Component {

    constructor(props) {
        super(props)
           this.state = {
              isTop: true,
              isApp: true,
              isCode: false,
              isAppDownload: false,
           }
     }

     handleMouseUserOver = (ev) => {
        // eslint-disable-next-line
        switch (ev) {
           case 1:
              this.setState({
                 isTop: false,
              })
              break;
           case 2:
              this.setState({
                 isApp: false,
                 isAppDownload: true,
              })
              break;
           case 3:
              this.setState({
                 isCode: true,
              })
              break;
        }
        
     }
     handleMouseOut = (ev) => {
        // eslint-disable-next-line
        switch (ev) {
           case 1:
              this.setState({
                 isTop: true,
              })
              break;
           case 2:
              this.setState({
                 isApp: true,
                 isAppDownload: false,
              })
              break;
           case 3:
              this.setState({
                 isCode: false,
              })
              break;
        }
        
     }
     backTop = () =>{
        console.log('回顶部');
         // document.getElementById('Content').scrollIntoView({ behavior: 'smooth' });
         document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    render() {
        return (
            <div className="Anchor">
                <div onMouseOver={() => this.handleMouseUserOver(1)}
                    onMouseLeave={() => this.handleMouseOut(1)}
                    onClick={() => this.backTop()}
                >

                    <img src={require("../../assets/image/home/huojian.png")} style={{display:this.state.isTop?'block':'none'}} alt="" />
                    <span style={{display:this.state.isTop?'none':'block'}}>返回顶部</span>

                </div>
                {/*<div onMouseOver={() => this.handleMouseUserOver(2)}*/}
                {/*    onMouseLeave={() => this.handleMouseOut(2)}*/}
                {/*>*/}
                {/*<span style={{display:this.state.isApp?'block':'none','color':'#3C9FEC'}}>APP</span>*/}
                {/*<span style={{display:this.state.isApp?'none':'block'}}>APP下载</span>*/}
                {/*<div className="appDownload" style={{display: this.state.isAppDownload?'block':'none'}}>*/}
                {/*    /!* <img src={require("../../assets/image/home/1-3.png")} alt=""/> *!/*/}
                {/*    <img src={require("../../assets/image/footer/app.png")} alt=""/>*/}
                {/*    <p>*/}
                {/*        下载至信手机客户端*/}
                {/*    </p>*/}
                {/*</div>*/}
                {/*</div>*/}
                <div onMouseOver={() => this.handleMouseUserOver(3)}
                    onMouseLeave={() => this.handleMouseOut(3)}
                >
                <img src={require("../../assets/image/home/erweima.png")} style={{display:this.state.isCode?'none':'block'}} alt="" />
                <span style={{display:this.state.isCode?'block':'none'}}>关注我们</span>
                <div className="appGuanzhu" style={{display: this.state.isCode?'block':'none'}}>
                    <img src={require("../../assets/image/footer/gongzhonghao.png")} alt=""/>
                    <p>
                    关注微信公众号
                    </p>
                </div>
                </div>
            </div>
        )
    }
}
