import React, { Component } from 'react';
import './Footer.less';
// import { browserHistory } from 'react-router';

export default class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isHome: true,
            current:  window.location.hash.replace(/\#/g, "")
         }
    }
    componentDidMount () {

        window.addEventListener('hashchange', () => {
            if(window.location.hash.replace(/\#/g, "") == '/home'){
                this.setState({
                    isHome: true
                })
            }else{
                this.setState({
                    isHome: false
                })  
            }

        })
      if(this.state.current == '/home'){
        this.setState({
            isHome: true
        })
      }else{
        this.setState({
            isHome: false
        })  
      }

    }
    
    tobeian () {
        window.open("http://www.beian.miit.gov.cn/publish/query/indexFirst.action");
    }

    render() {
        return (
            <div className="Footer">
                {/* <div className="blogroll" style={{display:this.state.isHome?'block':'none'}}>
                    <div>
                        友情链接：
                        <ul>
                            <li><a href="http://www.gsxt.gov.cn" target="_blank" rel="noopener noreferrer">国家企业信用信息公示系统</a></li>
                            <li><a href="http://zxgk.court.gov.cn/" target="_blank" rel="noopener noreferrer">中国执行信息公开网</a></li>
                            <li><a href="http://www.creditchina.gov.cn" target="_blank" rel="noopener noreferrer">信用中国</a></li>
                            <li><a href="https://ipcrs.pbccrc.org.cn" target="_blank" rel="noopener noreferrer">中国人民银行征信中心</a></li>
                            <li><a href="http://www.sipo.gov.cn/" target="_blank" rel="noopener noreferrer">国家知识产权局</a></li>
                            <li><a href="https://jxj.beijing.gov.cn/creditbj/" target="_blank" rel="noopener noreferrer">信用北京</a></li>
                        </ul>
                    </div>
                </div> */}
                <div className="foter">
                    <footer>

                        <div className="footer_left_left">
                            <div>
                                <p>友情链接：</p>
                                <ul>
                                    <li><a href="http://www.gsxt.gov.cn" target="_blank" rel="noopener noreferrer">国家企业信用信息公示系统</a></li>
                                    <li><a href="http://zxgk.court.gov.cn/" target="_blank" rel="noopener noreferrer">中国执行信息公开网</a></li>
                                    <li><a href="http://www.creditchina.gov.cn" target="_blank" rel="noopener noreferrer">信用中国</a></li>
                                    <li><a href="https://ipcrs.pbccrc.org.cn" target="_blank" rel="noopener noreferrer">中国人民银行征信中心</a></li>
                                    <li><a href="http://www.sipo.gov.cn/" target="_blank" rel="noopener noreferrer">国家知识产权局</a></li>
                                    <li><a href="https://jxj.beijing.gov.cn/creditbj/" target="_blank" rel="noopener noreferrer">信用北京</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="footer_left">
                            <div>
                                <div>
                                    <img src={require("../../assets/image/footer/dianhua-3.png")} alt="phone"/>
                                    <div>
                                        <p>客服热线 (工作日9:00-18:30)</p>
                                        <p>（86）010-52725777</p>
                                    </div>
                                </div>
                                <div>
                                    <img src={require("../../assets/image/footer/dingwei.png")} alt="place"/>
                                    <div>
                                        <p>北京市朝阳区裕民路12号中国国际科技会展中心B座7层705室</p>
                                    </div>
                                </div>
                                <div>
                                    <img src={require("../../assets/image/footer/mail.png")} alt="mail"/>
                                    <div>
                                        <p>业务联系</p>
                                        <p>邮箱地址：hkh@zhixin.net </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span>版权所有：中胜信用管理有限公司</span>
                                <span>Copyright © 2014-2023 zhixin.net All rights reserved</span>
                            </div>
                            <div>
                                <img src={require("../../assets/image/footer/xingzhuang.png")} alt="-"/>
                            </div>
                            <div>
                                <span>增值电信业务经营许可证B2-2015226</span>
                                <span className="beianhao" onClick={this.tobeian}>京ICP备15011610号-1</span>
                                <span>
                                    <img src={require("../../assets/image/footer/gongan.png")} alt="gongan"/>
                                    <span>京公网安备&nbsp;&nbsp;11010502033302号</span>
                                </span>
                            </div>

                        </div>
                        <div className="footer_right">
                            <div>
                                <p>关注微信公众号</p>
                                <img src={require("../../assets/image/footer/gongzhonghao.png")} alt="gzh"/>
                            </div>
                            <div>
                                <p>下载至信app</p>
                                <img src={require("../../assets/image/footer/app.png")} alt="app"/>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}
