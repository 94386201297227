import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import './Header.less';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // eslint-disable-next-line
            current:  window.location.hash.replace(/\#/g, "")
        }
    }
    handleClick = (e) => {
        this.setState({
            current: e.key,
        });
    };
    render() {
        console.log(this.state.current,'---------------')

        return (
            <div className='Header'>
                <div>
                    <div>
                        {/*<img src={require("../../assets/image/header/logo.png")} alt="logo" />*/}
                        <img src={require("../../assets/image/service/logo2.png")} alt="logo" />
                    </div>
                    <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
                        <Menu.Item key="/home">
                            <NavLink exact to='/home' activeClassName='route-active' > 首页 </NavLink>
                        </Menu.Item>
                        <Menu.Item key="/service">
                            <NavLink to='/service' activeClassName='route-active' > 产品服务 </NavLink>
                        </Menu.Item>
                        <Menu.Item key="/advantage">
                            <NavLink to='/advantage' activeClassName='route-active' > 核心优势 </NavLink>
                        </Menu.Item>
                        <Menu.Item key="/news">
                            <NavLink to='/news' activeClassName='route-active' > 新闻资讯 </NavLink>
                        </Menu.Item>
                        <Menu.Item key="/about">
                            <NavLink to='/about' activeClassName='route-active' > 关于我们 </NavLink>
                        </Menu.Item>
                        <Menu.Item key="/contact">
                            <NavLink to='/contact' activeClassName='route-active' > 加入我们 </NavLink>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
        )

    }
}