import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import Router from '../../router/Router'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Anchor from '../../components/Anchor/Anchor'

export default class Content extends Component {
   constructor(props) {
      super(props);
      this.state = {}
    }
    render(){
       return (
          <div className='Content' id="Content">
           <HashRouter>
               <Header />
               <Router />
               <Footer />
               <Anchor />
            </HashRouter>
          </div>
       )
    }
 }