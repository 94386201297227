import React, { lazy, Suspense } from 'react'
import { Route, Switch ,Redirect } from 'react-router-dom'

export default function Router() {
   return (
      <div className='router'>
         <Suspense fallback={<div>Loading...</div>}>
            <Switch>
               <Route
                  path='/home'
                  component={lazy(() => import(/* webpackChunkName: "home"*/ '../view/Home/Home' ))}
               />
               <Route
                  path='/about'
                  component={lazy(() => import(/* webpackChunkName: "about"*/ '../view/About/About' ))}
               />
               <Route
                  path='/service'
                  component={lazy(() => import(/* webpackChunkName: "service"*/ '../view/Service/Service' ))}
               />
               <Route
                  path='/advantage'
                  component={lazy(() => import(/* webpackChunkName: "advantage"*/ '../view/Advantage/Advantage' ))}
               />
               <Route
                  path='/news'
                  component={lazy(() => import(/* webpackChunkName: "news"*/ '../view/News/News' ))}
               />
               <Route
                  path='/newsdetail/:id'
                  component={lazy(() => import(/* webpackChunkName: "newsdetail"*/ '../view/News/Newsdetail' ))}
               />
               <Route
                  path='/dongtaidetail/:id'
                  component={lazy(() => import(/* webpackChunkName: "newsdetail"*/ '../view/News/Dongtaidetail' ))}
               />
               <Route
                  path='/contact'
                  component={lazy(() => import(/* webpackChunkName: "contact"*/ '../view/Contact/Contact' ))}
               />
               <Redirect exact path='/' to="/home" />
            </Switch>
         </Suspense>
      </div>
   )

}



