import React, { Component } from 'react';
import Content from './Content/Content';
export default class App extends Component {
   constructor(props) {
      super(props);
      this.state = {}
   }
   render() {
      return (
         <div className='container'>
            <Content />
         </div>
      )
   }
}